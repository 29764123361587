const routes = [
    {path: '/', name: 'Company Profiles', component: () => import('@/components/CompanyProfile.vue')}, // 默认路径重定向至首页
    {path: '/home', name: 'Home', component: () => import('@/components/HomePage.vue')},
    {path: '/company/profile', name: 'Company Profile', component: () => import('@/components/CompanyProfile.vue')},
    {path: '/adm/index', name: 'Admin', component: () => import('@/components/AdminPage.vue')},
    {path: '/person/page', name: 'Person Page', component: () => import('@/components/PersonPage.vue')},
    {path: '/login/page', name: 'Login Page', component: () => import('@/components/LoginPage.vue')},
    {path: '/menu/practise', name: 'practise Page', component: () => import('@/components/Menu/MenuPage.vue')},
    {path: '/menu/examination', name: 'examination Page', component: () => import('@/components/examination/ListPage.vue')},
    {path: '/intelligent/learning', name: 'intelligent learning Page', component: () => import('@/components/intelligent/ExaminationPage.vue')},
    {path: '/intelligent/predict', name: 'intelligent predict Page', component: () => import('@/components/intelligent/predictPage.vue')},
    {path: '/loginError', name: 'Error Page', component: () => import('@/components/error/LoginErrorPage.vue')},
    {
        path: '/exams/exam',
        name: 'exams Page',
        component: () => import('@/components/Exams/ExamsPage')
    },
    {
        path: '/practice/exam', name: 'practice Page', component: () => import('@/components/Exams/ExamsPage')
    },
    {
        path: '/collect/exam',
        name: 'collect Page',
        component: () => import('@/components/MyExam/CollectPage.vue')
    },
    {
        path: '/error/exam',
        name: 'error Page',
        component: () => import('@/components/MyExam/ErrorPage.vue')
    },
    {
        path: '/examination/imitate',
        name: 'imitate Page',
        component: () => import('@/components/examination/ExaminationPage.vue')
    },
]


export default routes