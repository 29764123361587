<template>
    <div v-if="level==='0'">
        <div>
            <el-menu
                    default-active="1"
                    class="el-menu-demo"
                    mode="horizontal"
                    :ellipsis="false"
            >
                <el-menu-item index="0">后台管理系统</el-menu-item>
                <div class="flex-grow"/>
            </el-menu>
        </div>
    </div>
    <div class="main" oncontextmenu="return false" oncopy="return false" onselectstart="return false">
        <router-view>
        </router-view>
    </div>
    <div style="margin-bottom: 150px;z-index: -999999">

    </div>
    <div class="footer" v-if="level!==null&&level==='1'">
        <div class="item" @click='handleClick("/home")'>
            <i class="iconfont icon-home" ></i>
            <p>首页</p>
        </div>
        <div class="item" @click='handleClick("/person/page")'>
            <i class="iconfont icon-personal" ></i>
            <p>我的</p>
        </div>
    </div>
</template>
<script>
import store from "@/store";

export default {
    data() {
        return {
            level: 1
        }
    },
    methods: {
        getLevel() {
            let href = window.location.href
            if (href.indexOf("/adm/index") !== -1) {
                this.level = '0'
            }else if (href.indexOf("/home")!==-1){
              this.level = '1'
            }else if (href.indexOf("/company/profile") !== -1||href.indexOf("/") !== -1){
              this.level = '2'
            }
        },
        handleClick(e) {
            if (e === '/person/page') {
                const that = this;
                if (!store.getters.getIsLogin) {
                    that.$router.push('/login/page')
                } else {
                    this.$router.push({
                        path: e
                    })
                }
            } else {
                this.$router.push({
                    path: e
                })
            }
        }
    },
    created() {
        if (sessionStorage.getItem('store')) {
            this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))));
        }
        window.addEventListener('beforeunload', () => {
            sessionStorage.setItem('store', JSON.stringify(this.$store.state));
        });
    },
    mounted() {
        this.getLevel()
    }
}

</script>

<style>
.head{
  background-color: rgb(213,30,35);
  color: white;
  font-size: 20px;
  padding: 15px;
  height: 20px;
}

.header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    border-top: 1px solid #d2d2d2;
    background-color: #f2f2f2;
    text-align: center;
}
.footer_admin {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  text-align: center;
}
.ba_footer{
    font-size: 10px;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    margin:0 auto;
    bottom: 10px;
}

a {
    text-decoration: none;
    color: #999;
}
.iconfont {
    font-size: 20px;
}

.item {
    padding: 5px 20%;
    float: left;
}

.item p {
    margin: 0;
    font-size: 15px;
}

.active {
    color: #108b70;
}

.main {
    height: 667px;
}

.flex-grow {
    flex-grow: 1;
}
</style>
