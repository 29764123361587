import axios from "axios";
import store from "@/store";
import router from "@/router";
axios.defaults.baseURL = window.configInfo.url;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// 使用库提供的默认配置创建实例
// 此时超时配置的默认值是 `0`

const instance = axios.create()

// 重写库的超时默认值
// 现在，所有使用此实例的请求都将等待2.5秒，然后才会超时
instance.defaults.timeout = 10000;

// http request拦截器 添加一个请求拦截器
instance.interceptors.request.use(function (config) {
    let token = store.state.token.content;
    config.headers.Token = token;
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    const isLogin = response.data.isLogin
    if (!isLogin){
        router.push({
            path: '/login/page'
        }).then(r => console.log(r))
    }
    return response;
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
});


export default instance;