import Vuex from 'vuex'

const store = new Vuex.Store({
    state: {
        user: {
            id: null,
            userName: null,
            realName: null,
            phone: null,
            age:null,
            sex:null,
            province:null,
            city:null,
            address:null,
            workplace:null,
            workName:null,
            graduateSchool:null,
            graduateTime:null,
            headImg:null,
            bgImg:null,
            level: null,
            birthday: null,
            createDate: null,
            signature: null,
        },
        exam:{
            examId:null,
            questionList:null,
            countdown:null,
            page:null,
            examTime:null
        },
        learnExam:{
            courseId:null,
            questionList:null,
            countdown:null,
            page:null
        },
        token: {
            content: null,
            expireTime:null
        },
        isLogin: false
    },
    getters: {
        getToken (state) {
            return state.token.content
        },
        getUser (state) {
            return state.user
        },
        getExam (state) {
            return state.exam
        },
        getLearnExam (state) {
            return state.learnExam
        },
        getIsLogin (state) {
            return state.token.content !== null
        }
    },
    mutations: {
        SetExam (state,exam) {
            state.exam = exam
        },
        SetLearnExam (state,learnExam) {
            state.learnExam = learnExam
        },
        SetToken (state, token) {
            state.token.content = token
        },
        LoginIn (state, user) {
            state.user = user
            state.isLogin = true
        },
        LoginOut: state => {
            state.token = {
                content: null,
                expireTime:null
            }
            state.user =  {
                id: null,
                userName: null,
                realName: null,
                phone: null,
                age:null,
                sex:null,
                address:null,
                workplace:null,
                workName:null,
                graduateSchool:null,
                graduateTime:null,
                headImg:null,
                bgImg:null,
                level: null,
                birthday: null,
                createDate: null,
                signature: null,
                expires_in:null
            },
            state.isLogin = false
        }
    },
    actions: {
        SetToken (context, token) {
            context.commit('SetToken', token)
        },
        SetExam (context, exam) {
            context.commit('SetExam', exam)
        },
        SetLearnExam (context, LearnExam) {
            context.commit('SetLearnExam', LearnExam)
        },
        LoginIn (context, user) {
            context.commit('LoginIn', user)
        },
        LoginOut (context) {
            context.commit('LoginOut')
        }
    }

})

export default store